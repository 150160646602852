<template>
  <section
    style="
      margin: 0 15px;
      border: 1px #e5e5e5 solid;
      max-height: calc(100vh - 70px);
    "
    class="overdueCollection scollDom"
    id="overdueCollection"
  >
    <el-row
      :gutter="24"
      style="padding: 0; background-color: #fff; padding-top: 0; margin: 0"
      class="searchBox"
    >
      <el-col class="searchPanel">
        <el-form
          @submit.native.prevent
          label-width="110px"
          style="background-color: #fff; padding-right: 20px; padding-top: 20px"
          :model="filter"
          ref="filter"
        >
          <el-col style="padding-left: 0">
            <el-col :lg="6" :md="15" style="padding: 0">
              <el-form-item label="客户名称：" prop="companyName">
                <el-input
                  v-model="filter.companyName"
                  placeholder="请输入"
                  class="searchInput"
                  style="width: 240px"
                  @keyup.enter.native="search"
                >
                  <template slot="suffix">
                    <el-icon
                      class="el-icon-search"
                      @click.native="search()"
                    ></el-icon> </template
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="9" :md="15" style="padding: 0">
              <el-form-item label="负责人：" prop="leaders" label-width="100px">
                <el-cascader
                  style="width: 240px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="options"
                  collapse-tags
                  v-model="filter.leaders"
                  :props="props"
                  @change="changeCreateUserIds"
                  @visible-change="handleVisibleChange(1, $event)"
                  :key="keyOfPartnerIds"
                  :filter-method="filterFun"
                ></el-cascader>
                <el-checkbox
                  style="width: 100px; margin-left: 15px"
                  v-model="filter.showDisableLeaders"
                  @change="changeShowDisableLeaders"
                  >停用账户</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :lg="9" :md="12" style="padding: 0">
              <el-form-item
                label="业绩分配顾问："
                prop="allocationConsultants"
                label-width="120px"
              >
                <el-cascader
                  style="width: 240px"
                  clearable
                  size="mini"
                  filterable
                  :show-all-levels="false"
                  :options="options2"
                  collapse-tags
                  v-model="filter.allocationConsultants"
                  :props="props"
                  @change="changeCreateUserIds1"
                  @visible-change="handleVisibleChange(2, $event)"
                  :key="keyOfCreateUserIds"
                  :filter-method="filterFun"
                ></el-cascader>
                <el-checkbox
                  style="width: 100px; margin-left: 15px"
                  v-model="filter.showDisableConsultants"
                  @change="changeShowDisableConsultants"
                  >停用账户</el-checkbox
                >
              </el-form-item>
            </el-col>
          </el-col>
        </el-form>
      </el-col>
    </el-row>
    <div class="box-title" style="padding-bottom: 0">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-button
            size="mini"
            style="margin-left: 15px"
            @click="exportList"
            :loading="exportLoading"
            >导出</el-button
          >
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0 && !listLoading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :header-cell-style="{
              'background-color': '#f5f7fa',
              'border-top': '1px #e5e5e5 solid',
            }"
            border
            v-horizontal-scroll
            :data="ListData"
            v-sticky="{ top: 0, parent: '.scollDom' }"
            class="tabBorder custor notableBorder"
            v-loading="listLoading"
            :span-method="objectSpanMethod"
            style="margin: 10px 0 10px; border-top: 0"
          >
            <el-table-column label="客户名称" align="left" width="240" fixed>
              <template slot-scope="scope">
                <span
                  class="tabHref"
                  @click="drawToCustomer(scope.row.companyId)"
                  >{{ scope.row.companyName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="负责人"
              align="left"
              fixed
              prop="leaderName"
              width="150"
            ></el-table-column>
            <el-table-column label="候选人" align="left" fixed width="100">
              <template slot-scope="scope">
                <span
                  class="tabHref"
                  @click="drawToCandicate(scope.row.candidateId)"
                  >{{ scope.row.candidateName }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="预到岗时间"
              align="left"
              prop="expectEntryTime"
              width="120"
            ></el-table-column>
            <el-table-column
              label="实际到岗时间"
              align="left"
              prop="entryTime"
              width="120"
            >
            </el-table-column>
            <!-- <el-table-column
              label="开票日期"
              align="left"
              prop="actualMakeInvoiceDate"
              width="120"
            ></el-table-column> -->
            <el-table-column
              label="累计开票金额"
              align="left"
              prop="actualMakeInvoiceMoney"
              width="120"
            ></el-table-column>
            <el-table-column
              label="累计到账金额"
              align="left"
              prop="accountMoney"
              width="120"
            ></el-table-column>
            <el-table-column
              label="到账状态"
              align="left"
              prop="accountStatus"
              width="170"
            ></el-table-column>
            <el-table-column
              label="约定到账日期"
              align="left"
              prop="conventionToAccountDate"
              width="120"
            ></el-table-column>
            <el-table-column
              label="超期天数"
              align="left"
              prop="overDueDays"
              width="80"
            ></el-table-column>
            <el-table-column
              label="付款周期"
              align="left"
              prop="paymentCycle"
              width="170"
            >
              <template slot="header" slot-scope="scope">
                <span>付款周期</span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="显示创建合同时填写的【付款周期】信息"
                  placement="bottom"
                >
                  <i
                    class="iconfont icon icon-wenhao-xiangsu"
                    style="font-size: 14px"
                  ></i>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{ scope.row.paymentCycle }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              align="left"
              prop="comment"
              width="400"
              :key="tableKey"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.comment && scope.row.comment[0]">
                  <span style="color: #999"
                    >{{ scope.row.comment[0].createdTime }}
                    {{ scope.row.comment[0].userName }}</span
                  >
                  <p style="white-space: break-spaces">
                    {{ scope.row.comment[0].commentContent }}
                  </p>
                </div>
                <div
                  v-for="(i, index) in scope.row.comment"
                  :key="index"
                  v-show="index > 0 && scope.row.isshowComment"
                >
                  <span style="color: #999"
                    >{{ i.createdTime }} {{ i.userName }}</span
                  >
                  <p style="white-space: break-spaces">
                    {{ i.commentContent }}
                  </p>
                </div>
                <span
                  class="tabHref"
                  v-if="scope.row.comment.length > 1"
                  @click="handleComment(scope.$index)"
                  >共{{ scope.row.comment.length }}条</span
                >
              </template>
            </el-table-column>
            <el-table-column
              label="预计到账日期"
              align="left"
              prop="expectAccountDate"
              width="120"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.expectAccountDate }}</span>
                <i
                  class="el-icon-edit"
                  @click="
                    editDate(
                      scope.row.projectResumeId,
                      scope.row.expectAccountDate
                    )
                  "
                ></i>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="left"
              prop="entryTime"
              width="120"
            >
              <template slot-scope="scope">
                <p class="tabHref" @click="addRemarks(scope.row.projectResumeId)">
                  添加备注
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="业绩分配顾问"
              align="left"
              prop="allocationConsultant"
              width="150"
            ></el-table-column>
            <el-table-column
              label="分配比例"
              align="left"
              prop="allocationPercent"
              width="80"
            ></el-table-column>
            <el-table-column
              label="实际开票业绩"
              align="left"
              prop="actualInvoiceMoney"
              width="120"
            ></el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0 && !listLoading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :pager-count="5"
            :page-size="pagination.pageSize"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
            style="margin-bottom: 20px"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>
    <el-drawer
      :visible.sync="drawerVisible"
      custom-class="drawerClass"
      direction="ttb"
      :modal="false"
      append-to-body
      :before-close="handleClose"
    >
      <div slot="title" class="drawerTitle">
        <i
          v-if="drawerTitle == '客户'"
          class="drawIcon iconfont icon-kh_xq"
        ></i>
        <i
          v-if="drawerTitle == '人才'"
          class="drawIcon iconfont icon-rc_bt"
        ></i>
        {{ drawerTitle }}
      </div>
      <div class="drawerCon">
        <addoreEditCus
          v-if="drawerTitle == '编辑客户' && drawerVisible"
          @loadList="loadList"
          @tuComInfo="detailsPop"
          :customId="customId"
        ></addoreEditCus>
        <customerInfo
          v-if="drawerTitle == '客户' && drawerVisible"
          @toEdit="toEditComInfo"
          :customId="customId"
          :activeToInfo="activedToInfo"
          @loadList="loadList"
        ></customerInfo>
        <resumeDetails
          v-if="drawerTitle == '人才' && drawerVisible"
          :resumeDetailId="resumeId"
          :hover="hoverInfo"
          :repeatResume="repeatResume"
        ></resumeDetails>
      </div>
    </el-drawer>
    <el-dialog
      width="600px"
      :title="digTitle"
      :visible.sync="digVisible"
      :close-on-click-modal="false"
      append-to-body
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        v-if="digVisible"
        label-width="120px"
        ref="digForm"
        :model="digForm"
      >
        <el-form-item
          label="备注内容："
          prop="comment"
          v-if="digTitle == '添加备注'"
          :rules="[{ required: true, message: '请填写备注', trigger: 'blur' }]"
        >
          <el-input
            v-model="digForm.comment"
            size="mini"
            type="textarea"
            :rows="2"
            maxlength="100"
            placeholder="请填写备注"
            style="width: 400px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="预计到账日期："
          prop="expectAccountDate"
          v-if="digTitle == '编辑预计到账日期'"
          :rules="[
            {
              required: true,
              message: '请选择预计到账日期',
              trigger: 'change',
            },
          ]"
        >
          <el-date-picker
            v-model="digForm.expectAccountDate"
            type="date"
            placeholder="请选择预计到账日期"
            value-format="yyyy-MM-dd"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button plain @click="handleClose" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="saveDigForm"
          :loading="saveChangeLoading"
          size="mini"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  permissionTree, //获取用户的组织架构
  financeTaskOverDuePagedList, //逾期回款分页信息
  financeTaskAddOverDueComment, //添加备注
  financeTaskAddOrEditeExpectAccountDate, //新增或编辑逾期财务任务的预计到账时间
  financeTaskExportOverdue,
} from "../../api/api";
import { ApiBaseUrl } from "../../api/http";
import customerInfo from "./../../components/customer/customerInfo";
import addoreEditCus from "./../../components/customer/addorEditCustomer";
import resumeDetails from "../../components/resumeDetail";
export default {
  components: { customerInfo, addoreEditCus, resumeDetails },
  data() {
    return {
      filter: {
        companyName: "",
        leaders: [],
        allocationConsultants: [],
        showDisableLeaders: false, //是否展示停用负责人账户
        showDisableConsultants: false, //是否展示停用顾问账户
      },
      options: [], //负责人
      options2: [], //顾问
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
        checkStrictly: false,
      },
      allocationConsultants: [],
      leaders: [],
      keyOfCreateUserIds: 0,
      keyOfPartnerIds: 0,
      exportLoading: false,
      baseUrl: ApiBaseUrl,
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      listLoading: false,
      customId: "", //客户id
      resumeId: null, //候选人id
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      activedToInfo: "1",
      drawerVisible: false,
      drawerTitle: "",
      ListData: [],
      spanArr: [],
      digTitle: "",
      digVisible: false,
      saveChangeLoading: false,
      digForm: {
        expectAccountDate: "",
        comment: "",
        projectResumeId: "",
      },
      tableKey: 0,
    };
  },
  created() {
    this.permissionTree(1); //获取用户组织架构
    this.permissionTree(2); //获取用户组织架构
    this.loadList();
  },
  methods: {
    changeCreateUserIds(e) {
      this.leaders = e.map((item) => {
        return (item = item[item.length - 1]);
      });
      this.search();
    },
    changeCreateUserIds1(e) {
      this.allocationConsultants = e.map((item) => {
        return (item = item[item.length - 1]);
      });
      this.search();
    },
    handleVisibleChange(type, e) {
      if (type == 1) {
        if (!e) {
          this.keyOfPartnerIds++;
        }
      } else {
        if (!e) {
          this.keyOfCreateUserIds++;
        }
      }
    },
    changeShowDisableLeaders(e) {
      //展示停用账户
      this.permissionTree(1);
    },
    changeShowDisableConsultants(e) {
      //展示停用账户
      this.permissionTree(2);
    },
    // 获取用户的组织架构
    permissionTree(val) {
      permissionTree({
        type: "顾问",
        showDisableUser:
          val == 1
            ? this.filter.showDisableLeaders
            : this.filter.showDisableConsultants,
      }).then((res) => {
        if (res.success) {
          if (val == 1) {
            this.options = res.result;
          } else {
            this.options2 = res.result;
          }
        }
      });
    },
    search() {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 50;
      this.loadList();
    },
    //导出
    exportList() {
      this.exportLoading = true;
      financeTaskExportOverdue({
        page: {
          current: this.pagination.pageNo,
          size: this.pagination.pageSize,
        },
        companyName: this.filter.companyName,
        showDisableLeaders: this.filter.showDisableLeaders,
        allocationConsultants: this.allocationConsultants,
        showDisableConsultants: this.filter.showDisableConsultants,
        leaders: this.leaders,
      }).then((res) => {
        this.exportLoading = false;
        if (res.success) {
          location.href = this.baseUrl + res.result;
        }
      });
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pagination.pageNo = val;
      this.loadList();
    },
    //获取分页列表
    loadList() {
      $("#overdueCollection").scrollTop(0);
      this.listLoading = true;
      financeTaskOverDuePagedList({
        page: {
          current: this.pagination.pageNo,
          size: this.pagination.pageSize,
        },
        companyName: this.filter.companyName,
        showDisableLeaders: this.filter.showDisableLeaders,
        allocationConsultants: this.allocationConsultants,
        showDisableConsultants: this.filter.showDisableConsultants,
        leaders: this.leaders,
      }).then((res) => {
        if (res.success) {
          this.ListData = res.result.records;
          this.ListData = this.ListData.map((item) => {
            item.isshowComment = false;
            for (let key in item) {
              if (item[key] == null) {
                item[key] = "-";
              }
            }
            return item;
          });
          let pos = 0;
          this.spanArr = [];
          for (var i = 0; i < this.ListData.length; i++) {
            if (i === 0) {
              this.spanArr.push(1);
              pos = 0;
            } else {
              // 判断当前元素与上一个元素是否相同,因合并第一个所以[0]
              if (
                this.ListData[i].projectResumeId ===
                this.ListData[i - 1].projectResumeId
              ) {
                this.spanArr[pos] += 1;
                this.spanArr.push(0);
              } else {
                this.spanArr.push(1);
                pos = i;
              }
            }
          }
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },
    // 表格合并单元格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex <= 13) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    //人才详情
    drawToCandicate(id) {
      this.drawerVisible = true;
      this.resumeId = id;
      this.drawerTitle = "人才";
    },
    //客户
    drawToCustomer(id) {
      this.customId = id;
      this.drawerVisible = true;
      this.drawerTitle = "客户";
    },
    //编辑客户
    toEditComInfo(id) {
      this.drawerTitle = "编辑客户";
      this.customId = id;
    },
    //客户详情
    detailsPop(id) {
      this.drawerVisible = true;
      this.drawerTitle = "客户";
      this.customId = id; //客户id
      this.activedToInfo = "1";
    },
    //关闭抽屉
    handleClose(done) {
      //   done();
      this.drawerVisible = false;
      this.digVisible = false;
      this.digForm = {
        expectAccountDate: "",
        comment: "",
        projectResumeId: "",
      };
    },
    //显示/隐藏备注
    handleComment(index) {
      let item = this.ListData[index];
      item.isshowComment = !item.isshowComment;
      this.$set(this.ListData, index, item);
      this.tableKey++;
    },
    //添加备注
    addRemarks(id) {
      this.digVisible = true;
      this.digTitle = "添加备注";
      this.digForm.projectResumeId = id;
    },
    //编辑预计到账日期
    editDate(id, date) {
      this.digVisible = true;
      this.digTitle = "编辑预计到账日期";
      this.digForm.projectResumeId = id;
      this.digForm.expectAccountDate = date && date !== "-" ? date : "";
    },
    //弹窗保存
    saveDigForm() {
      this.$refs["digForm"].validate((valid) => {
        if (valid) {
          let reqName = null;
          if (this.digTitle == "添加备注") {
            reqName = financeTaskAddOverDueComment;
          } else if (this.digTitle == "编辑预计到账日期") {
            reqName = financeTaskAddOrEditeExpectAccountDate;
          }
          reqName(this.digForm).then((res) => {
            if (res.success) {
              this.$message.success("操作成功");
              this.handleClose();
              this.search();
            }
          });
        }
      });
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
  },
};
</script>
<style lang='scss' scoped>
.searchPanel {
  :deep(.el-form-item__content) {
    display: flex;
  }
}
</style>